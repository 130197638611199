












import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  components: {
    InsCmsCat1: () => import('@/components/business/cms/InsCmsCat1.vue')
  }
})
export default class InsCmsCat13 extends Vue {
  @Prop() private cmsCategory!: object;

  get id () {
    console.log(this.$route.params.id, '当前id');
    return this.$route.params.id;
  }
}
